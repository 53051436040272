import { toast } from 'react-toastify'
import { isMobileOnly, isAndroid, isIOS } from 'react-device-detect'
import moment from 'moment'
import UCookies from 'universal-cookie'
import Router from 'next/router'
import mixpanel from 'mixpanel-browser'
import { useLayoutEffect, useState } from 'react'
import { DOMAIN_WITH_COMMON_COOKIES as DomainWithCommonCookies } from '@/utils/constant'
import CustomToast from '@/components/Common/CustomToast'
import { checkStaticURL } from 'pages/api/cms/cmsService'

let globalLanguage = 'en'

export const ContactNumber = 8001233227
export const AuctionContactNumber = 920034771
export const countryCode = +966
export const alphaArabicRegex = /^[\u0600-\u06FFa-zA-Z\s]+$/
export const urlOnTheBasisOfEnviorment = () => process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true'

/**
 *
 * @param {*} lang
 */
export const setLanguage = (lang) => {
  globalLanguage = lang
}

/**
 *
 * @returns
 */
export const getLanguage = () => globalLanguage

/**
 *
 * @param {*} lastUpdatedTime
 * @returns
 */
export const getDaysAgo = (lastUpdatedTime) => {
  const now = new Date().getTime()
  const updatedTime = new Date(lastUpdatedTime).getTime()
  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = now - updatedTime
  return Math.round(diffInTime / oneDay)
}

/**
 *
 * @param {*} phone
 * @returns
 */
export function validatePhone(phone = '') {
  return phone.startsWith('05') && phone.length === 10
}
/**
 * * This method allows you to create a string from array of strings and also gives option to add separator.
 * @param {*} arr Array of String which is to be converted to single string
 * @param {*} separatorTextOrSymbol Text or symbol which will be added after the first element which will work as separator for display purpose
 *
 * */
export function convert_arrayofStrings_to_String(arr = [], separatorTextOrSymbol = '') {
  let resultString = ''
  arr.map((ele, index) => {
    if (ele != '') {
      if (index == 0) {
        resultString = ele
      } else if (arr.length > index) {
        resultString = `${resultString} ${separatorTextOrSymbol}  ${ele}`
      } else if (index == arr.length - 1) {
        resultString = resultString + ' ' + ele
      }
    }
  })
  return resultString
}

export function getSecondsBetweenTwoDates(date1, date2) {
  return date1.diff(date2, 'seconds')
}
/**
 *
 * @param {*} boundryData
 * @returns
 */
export const getMapBound = (boundryData) => {
  if (!window.google) return
  const bounds = new window.google.maps.LatLngBounds()
  for (const boundry of boundryData) {
    bounds.extend(new window.google.maps.LatLng(boundry.latitude, boundry.longitude))
  }
  return bounds
}

/**
 *
 * @param {*} n
 * @param {*} lang
 * @returns
 */
export const priceConverter = (n) => {
  let symbol
  if (n > 999 && n <= 99999) {
    return numberWithCommas(n) // return number with comma if 999 < number < 99999
  } else if (n > 99999 && n < 999999) {
    symbol = 'K'
    const kValue = (n / 1000).toFixed(2) + symbol
    return kValue.replace('.00', '') // convert to K for number from > 1000 < 1 million
  } else if (n >= 999999) {
    symbol = 'M'
    const mValue = (n / 1000000).toFixed(2) + symbol
    return mValue.replace('.00', '') // convert to M for number from > 1 million
  } else if (n <= 999) {
    return n // if value < 1000, nothing to do
  }
}

/**
 *
 * @param {*} text
 * @param {*} font
 * @returns
 */
export const calculateTextWidth = (text, font) => {
  let canvas = calculateTextWidth.canvas || (calculateTextWidth.canvas = document.createElement('canvas'))
  let context = canvas.getContext('2d')
  context.font = font
  let metrics = context.measureText(text)
  return metrics.width
}

const toastProperties = {
  hideProgressBar: true,
  autoClose: 2000,
}

/**
 ** Show toast message with red background
 * @param {*} message : string
 */
export const showErrorToast = (message) => {
  const desktopPosition = window.location.pathname.includes('/en') ? 'bottom-right' : 'bottom-left'
  toast.error(message, {
    ...toastProperties,
    position: isMobileOnly ? 'bottom-left' : desktopPosition,
    theme: 'colored',
  })
}

/**
 ** Show toast message with green background
 * @param {*} message string
 */

export const showSuccessToast = (message, customClass = false, topPosition = false) => {
  const customSuccessStyle = {
    backgroundColor: '#10A26F', // Change this to your desired background color
    color: 'white',
  }
  const desktopPosition = window.location.pathname.includes('/en') ? 'bottom-right' : 'bottom-left'
  const mobilePosition = isMobileOnly ? 'bottom-left' : desktopPosition
  const position = topPosition ? 'top-right' : mobilePosition

  toast.success(message, {
    ...toastProperties, // You need to define toastProperties somewhere
    theme: 'colored',
    ...(customClass ? { style: customSuccessStyle } : {}),
    position, // Use the 'position' variable here
  })
}

export const showCustomToast = (
  message,
  type = 'bid-success',
  lang = 'ar',
  id = null,
  _isTimeOut = true,
  props = null,
  isWallet = false,
) => {
  const ids = id ? { toastId: id } : {}

  let position = lang === 'ar' ? 'top-left' : 'top-right' // Default position
  if (type === 'withdraw-update-success') {
    const bottomPosition = lang === 'ar' ? 'bottom-left' : 'bottom-right'
    position = isMobileOnly ? 'top-center' : bottomPosition
  }
  toast(message, {
    ...toastProperties,
    theme: 'colored',
    type,
    rtl: lang == 'ar' ? true : false,
    position,
    ...ids,
    ...props,
    className: isWallet && lang === 'ar' ? 'customToast' : 'customClasseng',
  })
}

export const divide_number_string_into_multiple_digits = (numberString, digit = 3) => {
  const separatedNumbersArray = numberString.split('')
  let finalResult = ''
  separatedNumbersArray.forEach((num, index) => {
    if (index % digit == digit - 1) finalResult = `${finalResult}${num} `
    if (index % digit < digit - 1) finalResult = `${finalResult}${num}`
  })
  return finalResult
}

export const divide_number_string_input_and_add_space = (event, type = 'phone') => {
  const isDeletingCharacter = event.nativeEvent.inputType === 'deleteContentBackward'
  const isPastingText = event.nativeEvent.inputType === 'insertFromPaste'
  let { target } = event,
    position = target.selectionEnd,
    length = target.value.length
  target.value = target.value.replace(/ /g, '')
  
  if (type === 'iban') {
    target.value = `${target.value.slice(0, 2).trim()}${target.value.slice(2).length > 0 ? ' ' : ''}${target.value
      .slice(2)
      .replace(/(.{4})/g, '$1 ')
      .trim()}`
  } else if (type === 'phone') {
    target.value = target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{3})/g, '$1 ')
      .trim()
  }

  if (isPastingText) {
    target.selectionEnd = target.value.length
    return
  }

  if (isDeletingCharacter) {
    target.selectionEnd = 
      position +
      (target.value.charAt(position - 1) === ' ' &&
      target.value.charAt(length - 1) === ' ' &&
      length !== target.value.length
        ? 1
        : 0)
  } else {
    target.selectionEnd = 
      position +
      (target.value.charAt(position - 1) === ' ' ? 1 : 0)
  }
}

export const onShareClick = (t) => {
  const pageurl = window.location.href
  if (navigator.share) {
    navigator
      .share({
        url: pageurl,
      })
      .then()
      .catch()
  } else {
    console.warn('Sorry! Your browser does not support Web Share API')
    navigator.clipboard.writeText(pageurl)
    toast.success(t('auctionCommon.COPY_SCUCCESS'), {
      ...toastProperties,
      theme: 'colored',
    })
  }
}

export const numberWithCommas = (x, withDecimal=true) => {
  if (x === null || x === undefined) return null
  let num_decimals = 0
  if (x.toString().indexOf('.') !== -1 && withDecimal) {
    num_decimals = 2
  }
  const converted = Number(x).toLocaleString('en-US', {
    minimumFractionDigits: num_decimals,
    maximumFractionDigits: num_decimals,
  })
  if (!isNaN(Number(x))) return converted
  return x
}

export const ArabicSar = (lang, Sar, amount = '') => {
  let result = ''

  if (lang === 'ar') {
    result = amount ? `${numberWithCommas(amount)} ${Sar}` : Sar
  } else {
    result = amount ? `${Sar} ${numberWithCommas(amount)}` : Sar
  }

  return result
}

export const numberWithArabicCommas = (x, lang) => {
  if (x === null || x === undefined) return null
  if (lang == 'ar') {
    return numberWithCommas(x).replace(',', '،')
  }
  return numberWithCommas(x)
}

export const updateAuctionData = async (auctionId, index, authToken, auctionList, setAuctionList, lang) => {
  let queryParams = encodeURI(`id=${auctionId}`)
  const res = await fetch(`/api/auction/crud?apiUrl=auction-detail&queryParams=${queryParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      locale: lang,
      authorization: authToken ?? '',
    },
  })
  const response = await res.json()
  const auctionData = response.data.data
  delete auctionData.AuctionConfigTranslations
  delete auctionData.AuctionSlabs
  const tempList = [...auctionList]
  tempList[index] = { ...tempList[index], ...auctionData }
  setAuctionList(tempList)
}

export const getDateMonthConverted = (t, date, dateString) => {
  let month = ''
  if (dateString) {
    month = moment(dateString).format('MMM')
  } else {
    month = moment(date).format('MMM')
  }

  return date.replace(month, t(`months.${month.toUpperCase()}`))
}

export const getDateWithSlash = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const getDateFullMonthConverted = (t, date, dateString) => {
  let month = ''
  if (dateString) {
    month = moment(dateString).format('MMMM')
  } else {
    month = moment(date).format('MMMM')
  }
  return date.replace(month, t(`fullMonths.${month.toUpperCase()}`))
}

export const removeCookies = () => {
  const domain = location.host
  const cookieOptions = {
    path: '/',
  }
  if (domain.split('.').length > 1) {
    const domainParts = domain.split('.')
    domainParts.shift()
    cookieOptions.domain = '.' + domainParts.join('.')
  }
  window.localStorage.removeItem('savedAuctionList')
  window.localStorage.removeItem('yourActivityList')
  window.localStorage.removeItem('propertyStore')
  window.localStorage.removeItem('register')
  const cookie = new UCookies()
  cookie.remove('propertyStore', cookieOptions)
  cookie.remove('auctionActivityList', cookieOptions)
  cookie.remove('savedAuctionList', cookieOptions)
  cookie.remove('authToken', cookieOptions)
  cookie.remove('userId', cookieOptions)
  cookie.remove('refreshToken', cookieOptions)
  cookie.remove('authStoredDate', cookieOptions)
  if (domain.match(/[a-z]/i) == null) {
    delete cookieOptions.domain
    cookie.remove('authToken', cookieOptions)
    cookie.remove('userId', cookieOptions)
    cookie.remove('refreshToken', cookieOptions)
    cookie.remove('authStoredDate', cookieOptions)
  } else if (domain.split('.').length > 1) {
    cookieOptions.domain = '.' + location.host
    cookie.remove('authToken', cookieOptions)
    cookie.remove('userId', cookieOptions)
    cookie.remove('refreshToken', cookieOptions)
    cookie.remove('authStoredDate', cookieOptions)
  }
}

export const getInitialRemainingTime = (auctionDetails, auctionPropertyDetails) => {
  const serverTime = auctionPropertyDetails ? auctionPropertyDetails?.data?.serverTime : auctionDetails?.serverTime
  if (auctionDetails?.isExtended && auctionDetails?.status?.toLowerCase() == 'live') {
    return getSecondsBetweenTwoDates(moment(auctionDetails?.endDate), moment(serverTime))
  }
  return getSecondsBetweenTwoDates(
    moment(auctionDetails?.status?.toLowerCase() == 'upcoming' ? auctionDetails?.startDate : auctionDetails?.endDate),
    moment(serverTime),
  )
}
export const gettotalDuration = (auctionDetails, auctionPropertyDetails ) => {
  if (auctionDetails?.isExtended && auctionDetails?.status?.toLowerCase() == 'live') {
    return getSecondsBetweenTwoDates(moment(auctionDetails?.endDate), moment(auctionDetails?.serverTimeOnExtension))
  }
  const createdAt = auctionPropertyDetails ? auctionPropertyDetails?.data?.createdAt : auctionDetails?.createdAt
  return getSecondsBetweenTwoDates(
    moment(auctionDetails?.status?.toLowerCase() == 'upcoming' ? auctionDetails?.startDate : auctionDetails?.endDate),
    moment(auctionDetails?.status?.toLowerCase() == 'upcoming' ? createdAt : auctionDetails?.startDate),
  )
}

export const redirectToURL = (redirectUrlAfterLogin) => {
  Router.push(`/login?redirectUrl=${redirectUrlAfterLogin}`)
}

export const pushEvent = (window, lang, eventName, PageType, auctionData, additionalParameter = {}) => {
  window.dataLayer = window.dataLayer || []
  const bidAmountLivePast = auctionData?.highestBid ? auctionData?.highestBid : auctionData?.bidStartingPrice
  const details = {
    userID: window.localStorage.getItem('userId') ?? 'Non Logged-IN User',
    user_mobile: window?.localStorage.getItem('encryptedPhoneNumber') || '',
    language: lang === 'en' ? 'English' : 'Arabic',
    Page_type: PageType,
    City_Selected: auctionData?.property?.propertyInfo.city ?? auctionData?.city,
    Auction_ID: auctionData?.id ?? auctionData?.auctionUUID,
    Auction_Status: auctionData?.status,
    Registration_window_status: auctionData?.auctionRegistrationDetails?.isRegistrationOpen ? 'opened' : 'closed',
    groupAuction_id: auctionData?.infathAuctionGroupInfo?.auctionGroupId ,
    Seller_type: auctionData?.sellerType,
    Auction_name: auctionData?.propertyInfo?.propertyName ?? auctionData,
    Property_location: auctionData?.propertyInfo?.address ?? auctionData?.address,
    Property_id: auctionData?.propertyId,
    Bid_Amount: new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 9,
    }).format(
      auctionData?.status == 'live' || auctionData?.status == 'past'
        ? bidAmountLivePast
        : auctionData?.bidStartingPrice ?? 0,
    ),
    // Property_Info: auctionData?.property,
    ...additionalParameter,
    marketing_channel_grouping: sessionStorage.getItem('marketingChnnlGrp')? sessionStorage.getItem('marketingChnnlGrp'): "",
    custom_source: sessionStorage.getItem('cstmSrc')? sessionStorage.getItem('cstmSrc'): "",
    custom_medium: sessionStorage.getItem('cstmMed')? sessionStorage.getItem('cstmMed'): "",
    custom_campaign: sessionStorage.getItem('cstmCmpgn')? sessionStorage.getItem('cstmCmpgn'): "",
    custom_content: sessionStorage.getItem('cstmCntnt')? sessionStorage.getItem('cstmCntnt'): "",
  }
  window.dataLayer.push({
    event: eventName,
    details,
  })
  mixpanel.track(eventName, details)
}

// will upadate paths on all router events
/**
 *
 * @param {object} router
 * @returns {null}
 *
 */
export const updateRouterPath = (router) => {
  if (sessionStorage.getItem('paths')) {
    let paths = JSON.parse(sessionStorage.getItem('paths'))
    if (paths[paths.length - 1] === router.asPath) return void 0 // to avoid updating same last path
    paths.push(router.asPath)
    sessionStorage.setItem('paths', JSON.stringify(paths))
  } else {
    let paths = []
    paths.push(router.asPath)
    sessionStorage.setItem('paths', JSON.stringify(paths))
  }
}

// return url string of previous visited page
export const getPreviousPath = () => {
  let path = JSON.parse(sessionStorage.getItem('paths'))
  if (path) {
    if (path.length === 1) return path[0]
    else return path[path.length - 1]
  } else return '/'
}

export const pushDataLayerEventHandler = (window, lang, eventName, pageType, data = {}) => {
  let platform
  if (isIOS) {
    platform = 'iOS'
  } else if (isAndroid) {
    platform = 'android'
  } else {
    platform = 'Web'
  }

  window.dataLayer = window.dataLayer || []
  const details = {
    login_status: window.localStorage.getItem('userId') ? true : false,
    user_mobile: window?.localStorage.getItem('encryptedPhoneNumber') || '',
    userID: window.localStorage.getItem('userId') ?? 'Non Logged-IN User',
    language: lang === 'en' ? 'English' : 'Arabic',
    page_type: pageType,
    platform,
    ...data,
    marketing_channel_grouping: sessionStorage.getItem('marketingChnnlGrp')? sessionStorage.getItem('marketingChnnlGrp'): "",
    custom_source: sessionStorage.getItem('cstmSrc')? sessionStorage.getItem('cstmSrc'): "",
    custom_medium: sessionStorage.getItem('cstmMed')? sessionStorage.getItem('cstmMed'): "",
    custom_campaign: sessionStorage.getItem('cstmCmpgn')? sessionStorage.getItem('cstmCmpgn'): "",
    custom_content: sessionStorage.getItem('cstmCntnt')? sessionStorage.getItem('cstmCntnt'): "",
  }
  window.dataLayer.push({
    event: eventName,
    details,
  })
  mixpanel.track(eventName, details)
}

const getPropertyCategory = (countryId, propertyDetails) => {
  if (countryId) {
    return countryId === 1 ? 'domestic' : 'international';
  } 
  return propertyDetails?.propertyRegionId !== 57 ? 'domestic' : 'international';
};

const getManagedBy = (propertyDetails, key) => {
  if (propertyDetails?.managedById) {
    return propertyDetails.managedById === 54 ? 'wasalt' : 'self';
  } 
  if (propertyDetails[key]?.managedById) {
    return propertyDetails[key].managedById === 54 ? 'wasalt' : 'self';
  }
  return '';
};

const getBaseData = (propertyDetails, key, attrDataLayerValues) => ({
  Property_id: propertyDetails.id,
  service: propertyDetails[key]?.propertyFor,
  title: propertyDetails[key]?.title,
  address: propertyDetails[key]?.address,
  city: propertyDetails[key]?.city || '',
  country: propertyDetails[key]?.country || 'Saudi Arabia',
  property_type: key === 'propertyInfo' ? propertyDetails[key]?.propertySubType : 'Compound',
  city_id: propertyDetails[key]?.cityId || '',
  conversion_unit: propertyDetails[key]?.conversionUnit || '',
  currency_type: propertyDetails[key]?.currencyType || '',
  ...attrDataLayerValues,
});

const getPropertyInfoData = (propertyDetails, key, data) => ({
  ...data,
  zone: propertyDetails[key]?.territory || '',
  conversion_price: propertyDetails[key]?.conversionPrice || '',
  furnishing_type: propertyDetails[key]?.furnishingType || '',
  possession_type: propertyDetails[key]?.possessionType || '',
  sale_price: propertyDetails[key]?.salePrice || '',
  rent: propertyDetails[key]?.expectedRent || '',
  rent_type: propertyDetails[key]?.expectedRentType || '',
});

const getOtherPropertyData = (propertyDetails, key, data) => ({
  ...data,
  zone: propertyDetails[key]?.district || '',
  salePriceMax: propertyDetails[key]?.salePriceMax,
  salePriceMin: propertyDetails[key]?.salePriceMin,
  completionDate: propertyDetails[key]?.completionDate,
});

export const formatPropertyDatalayerData = (propertyDetails = {}, countryId = null, key = 'propertyInfo') => {
  const attrDataLayerValues = {};
  const propertyCategory = getPropertyCategory(countryId, propertyDetails);
  const managedBy = getManagedBy(propertyDetails, key);

  let data = getBaseData(propertyDetails, key, attrDataLayerValues);
  data.property_category = propertyCategory;
  data.managed_by = managedBy;

  if (key === 'propertyInfo') {
    data = getPropertyInfoData(propertyDetails, key, data);
  } else {
    data = getOtherPropertyData(propertyDetails, key, data);
  }

  return data;
};

export const getYoutubeId = (link, isGDPVideo = false) => {
  const youtubeRegex = isGDPVideo
    ? /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=))([^#\&\?]+)/
    : /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
  return link.match(youtubeRegex)
}

export const downloadFile = async (url, fileName) => {
  let response = await fetch(url)
  let data = await response.blob()
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const numberWithoutCommas = (str) => {
  const val = str ? parseInt(str.replace(/\,/g, ''), 10) : str
  return isNaN(val) ? '' : val
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const trimStringFromStart = (text, totalLength) => text.substr(text.length - totalLength, totalLength)

export const getDateConvertedUsingAt = (t, dateToBeConverted, showAt = true, showFullMonth = false) => {
  const formattedDate = moment(dateToBeConverted).format(showFullMonth ? 'DD MMMM YYYY, ' : 'DD MMM, YYYY, ')
  const dateWithConversion = showFullMonth
    ? getDateFullMonthConverted(t, formattedDate, dateToBeConverted)
    : getDateMonthConverted(t, formattedDate, dateToBeConverted)

  const finalDate = showAt ? getDateMonthConverted(t, formattedDate, dateToBeConverted) : dateWithConversion

  const time = moment(dateToBeConverted).format('hh:mm')
  const period = moment(dateToBeConverted).format('hh:mm a').includes('am')
    ? t('auctionBidComponent.AM').toUpperCase()
    : t('auctionBidComponent.PM').toUpperCase()

  return `${finalDate} ${showAt ? t('eWallet.AT') : ''} ${time} ${period}`
}

export const getDateConvertedUsingAtArabic = (t, dateToBeConverted, lang) =>
  `${getDateMonthConverted(
    t,
    moment(dateToBeConverted).format(`DD MMM, YYYY${lang === 'ar' ? '،' : ','}`),
    dateToBeConverted,
  )} ${t('eWallet.AT')} ${moment(dateToBeConverted).format('hh:mm')} ${
    moment(dateToBeConverted).format('hh:mm a').includes('am')
      ? t('auctionBidComponent.AM').toUpperCase()
      : t('auctionBidComponent.PM').toUpperCase()
  }`

export const removeComma = (amount) => {
  let a = amount.replace(/\,/g, '') // 1125, but a string, so convert it to number
  a = parseInt(a, 10)
  return a
}

export const setSavedAuctionCookies = (auctionIds) => {
  const cookies = new UCookies()
  const domain = typeof location != 'undefined' && location.host
  const cookieOptions = {
    path: '/',
    secure: true,
  }
  if (domain && domain?.split('.').length > 1) {
    const domainParts = domain?.split('.')
    domainParts.shift()
    let isDomainInArray = DomainWithCommonCookies.includes(domain)
    if (isDomainInArray) {
      cookieOptions.domain = '.' + domainParts.join('.')
    } else {
      cookieOptions.domain = '.' + domain
    }
  }
  
  cookies.set('savedAuctionList', JSON.stringify(auctionIds), cookieOptions)
}

export const getAssetsBaseURL = () => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' && process.env.NEXT_PUBLIC_CDN_AVAILABLE == 'true')
    return process.env.NEXT_PUBLIC_CDN_BASE_FILE_URL
  return process.env.NEXT_PUBLIC_BASE_FILE_URL
}

export const handleBackGroundScroll = (type, scrollPosition) => {
  if (type == 'remove') {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    document.body.style.top = '-' + scrollPosition + 'px'
    document.body.style.width = '100%'
  } else {
    document.body.style.overflow = 'auto'
    document.body.style.position = null
    document.body.style.width = null
    document.body.style.scrollPo = null
    window.scrollTo(0, scrollPosition)
  }
}

export const getRemoteConfigInfo = (data = {}) => {
  let remoteConfigData =
    Object.keys(data).length > 0
      ? data?.map((item) => {
          return {
            [item?.entity_name]: item?.entity_value,
          }
        })
      : []
  return remoteConfigData.length > 0 ? remoteConfigData.reduce((acc, cur) => ({ ...acc, ...cur }), {}) : {}
}

export const isUserLoggedIn = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('authToken') || window.localStorage.getItem('userId')
  }
  return null
}

export const formatNumber = (number) => {
  return `(${number.substring(0, 4)}) ${number.substring(4, 6)} ${number.substring(6, 9)} ${number.substring(9, 13)}`
}

export const getHighlightedText = (text, highlight, customClass) => {
  highlight = highlight.trim()
  const highlightedText = text?.replace(
    new RegExp(highlight, 'gi'),
    (match) => `<strong style="font-weight:600">${match}</strong>`,
  )
  return <span className={customClass} dangerouslySetInnerHTML={{ __html: highlightedText }} />
}

const getUrl = (whatsappUrl) => {
  let urlValue = ''
  if (globalLanguage == 'ar') {
    if (whatsappUrl.includes('localhost') || whatsappUrl.includes('wasalt.sa')) {
      urlValue = whatsappUrl?.toString()?.split('?')[0]
    }
  } else {
    if (whatsappUrl !== '' && whatsappUrl !== undefined && whatsappUrl.length > 0) {
      if (whatsappUrl.includes('localhost') || whatsappUrl.includes('wasalt.sa')) {
        urlValue = whatsappUrl?.toString()?.split('?')[0]
      }
    }
  }
  return urlValue
}

export const redirectOnWhatsapp = (number, _redirectEndpoint, text, whatsAppMsg) => {
  let queryString = `phone=${number}`;
  if (text) {
    queryString += `&text=${text}`;
  }
  console.log('queryString',queryString)
  return `${process.env.NEXT_PUBLIC_WHATSAPP_REDIRECT_URL}?${queryString} ${whatsAppMsg}`;
};

export const diff_days = (date1, date2, _t) => {
  let days = date1.diff(date2, 'days')
  date2.add(days, 'days')
  return `${days}`
}

//Seller Type Constant
export const sellerType = {
  INFATH: 'au-infath',
  WASALT: 'au-wasalt',
}

// This function returns an object with status texts translated using the provided translation function 't'.
export const getStatusTextWithTranslation = (t) => ({
  live: t('auctionStatus.LIVE'),
  upcoming: t('auctionStatus.UPCOMING'),
  past: t('auctionStatus.CLOSED'),
  cancel: t('groupAssetCard.CANCELLED')
})
export const getStatusTextWithTranslationNew = (t) => ({
  live: t('groupAssetCard.live'),
  upcoming: t('groupAssetCard.upcoming'),
  past: t('groupAssetCard.closed'),
  reject: t('groupAssetCard.upcoming'),
  cancel: t('groupAssetCard.cancel')
})

// For producing Amount with currency with position depending on language and status for AuctionCard component
export const amountWithSar = (t, data, lang, status) => {
  let message;
  switch (status) {
    case 'live':
    case 'upcoming':
      message = `${t('transactionPopup.SAR')} ${data} ${t('transactionPopup.IS_BLOCKED')}`;
      break;
    case 'past':
      message = lang === 'en'
        ? `${t('transactionPopup.SAR')} ${data} ${t('transactionPopup.IS_REFUNDED')}`
        : `${t('transactionPopup.IS_REFUNDED_ARABIC')} ${data} ${t('transactionPopup.SAR')}`;
      break;
    default:
      message = '';
  }
  return message;
};

export const formatInfathResponse = (data, lang = 'ar') => {
  const formattedData = {}
  formattedData['id'] = data?.id
  formattedData['propertyId'] = data?.propertyId
  formattedData['seller'] = data?.sellerSlug
  formattedData['sellerSlug'] = data?.sellerSlug === 'au-infath' ? undefined : 'au-wasalt'
  formattedData['status'] = data?.status
  formattedData['startDate'] = data?.startDate
  formattedData['endDate'] = data?.endDate
  formattedData['sDepAmt'] = data?.sDepAmt
  formattedData['city_id'] = data?.city_id
  formattedData['title'] =
    lang === 'ar'
      ? data?.arabicGroupName || data?.property?.propertyInfo?.title
      : data?.englishGroupName || data?.property?.propertyInfo?.title || data?.title
  formattedData['englishGroupName'] = data?.englishGroupName
  formattedData['address'] = data?.address || data?.property?.propertyInfo?.address
  formattedData['auctionItems'] = data?.auctionItems
  formattedData['images'] = data?.groupImagesLink || data?.property?.propertyFiles?.images || data?.images
  formattedData['logo'] = data?.logo?.infathLogo
  formattedData['brokerageFirmLogo'] = data?.logo?.brokerageFirmLogo
  formattedData['duration'] = data?.duration
  formattedData['durationUnit'] = data?.durationUnit
  formattedData['isActive'] = data?.isActive
  formattedData['reservedPrice'] = data?.reservedPrice
  formattedData['bidStartingPrice'] = data?.bidStartingPrice
  formattedData['maximumBidIncrement'] = data?.maximumBidIncrement
  formattedData['bidDeposit'] = data?.bidDeposit
  formattedData['landArea'] = data?.landArea
  formattedData['vatBidder'] = data?.vatBidder
  formattedData['comissionBidder'] = data?.comissionBidder
  formattedData['vatSeller'] = data?.vatSeller
  formattedData['comissionSeller'] = data?.comissionSeller
  formattedData['sellerContractLink'] = data?.sellerContractLink
  formattedData['titleDeedLink'] = data?.titleDeedLink
  formattedData['auctionId'] = data?.auctionId
  formattedData['serverTime'] = data?.serverTime
  formattedData['currentHighestBid'] = data?.currentHighestBid
  formattedData['isRegistrationOpen'] = data?.auctionRegistrationDetails?.isRegistrationOpen
  formattedData['registrationStartDate'] = data?.auctionRegistrationDetails?.registrationStartDate
  formattedData['registrationEndDate'] = data?.auctionRegistrationDetails?.registrationEndDate
  formattedData['createdAt'] = data?.createdAt
  formattedData['activeBidders'] = data?.activeBidders
  formattedData['winnerUserId'] = data?.winnerUserId
  formattedData['followedCount'] = data?.followedCount
  formattedData['auctionTags'] = data?.auctionTags
  formattedData['disclaimer'] = data?.disclaimer
  formattedData['attributes'] = data?.property?.attributes
  formattedData['tags'] = data?.type
  formattedData['transactionStatus'] = data?.transactionStatus
  formattedData['groupId'] = data?.infathGroupAuction?.groupId
  formattedData['paymentsourceType'] = data?.paymentsourceType
  formattedData['subStatusType'] = data?.subStatusType
  formattedData['property'] = data?.property || {}
  formattedData['cancelDate'] = data?.cancelDate || ""
  if (data?.leftOverMs) {
    formattedData['leftOverMs'] = data?.leftOverMs
    formattedData['leftOverServerMs'] = data?.leftOverServerMs
  }
  return formattedData
}

//divide function only for testing purpose
export const divide = (a, b) => a / b

// Handle Days display visibility on the basis of Arabic translations
export const handleDaysVisibility = (duration, lang, t) => {
  switch (duration) {
    case 1:
      return t('propertyDetails.DAY_1')
    case 2:
      return t('propertyDetails.DAYS_2')
    default:
      if (duration >= 3 && duration <= 10) {
        switch (lang) {
          case 'en':
          case 'ar':
            return `${duration} ${t('propertyDetails.DAYS_3_10')}`
        }
      } else if (duration >= 11) {
        switch (lang) {
          case 'en':
          case 'ar':
            return `${duration} ${t('propertyDetails.DAYS')}`
        }
      }
      break
  }
}

// infath and walat auction pdf img render func
export const getBaseUrl = () => {
  return process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true'
    ? process.env.NEXT_PUBLIC_CDN_BASE_URL
    : process.env.NEXT_PUBLIC_DEV_BASE_FILE_URL
}

const mapBrochureData = (allData) => {
  return allData?.brochure;
};

const mapImageData = (data, groupId, baseUrl) => {
  return data?.images?.data?.map((image) => ({
    type: data?.images?.type ?? '',
    filename: image?.content ?? '',
    fromCdn: true,
    url: `${baseUrl}/infath/AuctionGroup/${groupId}/${encodeURI(image?.content)}/width=800,quality=75,format=auto`,
    thumb: data?.images?.thumbnail ?? '',
  })) ?? [];
};

const mapAdpImageData = (adpGallery, propertyDetails, isProduction, auctionDetails) => {
  return adpGallery?.images?.data?.map((image) => ({
    type: adpGallery?.images?.type ?? '',
    filename: image?.content ?? '',
    fromCdn: true,
    url: `${getAssetsBaseURL()}/properties/${propertyDetails?.id ?? auctionDetails?.propertyId}/images/${encodeURI(image?.content)}/width=808,quality=75,format=auto${isProduction ? ',width=800' : ''}`,
    thumb: adpGallery?.images?.thumbnail ?? '',
  })) ?? [];
};

export const renderBrochureAndGalleryWithConditions = ({
  isGDPAuction,
  selectedTab,
  allData,
  auctionDetails,
  groupId,
  t,
  propertyDetails,
  adpGallery
}) => {
  const baseUrl = getBaseUrl();
  const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';
  let content = [];

  switch (true) {
    case isGDPAuction && selectedTab === t('Details.Brochure'):
      content = mapBrochureData(allData);
      break;

    case isGDPAuction && selectedTab === t('Details.Images'):
      content = mapAdpImageData(adpGallery, propertyDetails, isProduction, auctionDetails);
      break;

    case isGDPAuction === undefined && auctionDetails?.groupName && selectedTab === t('Details.Images'):
      content = mapImageData(allData, groupId, getAssetsBaseURL());
      break;

    case isGDPAuction === undefined && auctionDetails?.groupName && selectedTab === t('Details.Brochure'):
      content = mapBrochureData(allData);
      break;

    default:
      content = allData?.images?.data?.map((img) => ({
        type: 'image',
        filename: img?.content ?? '',
        fromCdn: true,
        url: `${getAssetsBaseURL()}/properties/${auctionDetails?.propertyId}/images/${encodeURI(img?.content)}/quality=75,format=auto,width=800'`,
      })) ?? [];
      break;
  }

  return content;
};


export const bidHistory = (date, t, lang) => {
  if (lang === 'en') {
    return `${date} ${t('auctionGroupDetail.ago')}`
  } else {
    return `${t('auctionGroupDetail.ago')} ${date}`
  }
}

// This function, containsNumberPlus, checks if a given string contains a number followed by a plus sign (+).
export const containsNumberPlus = (string) => {
  var pattern = /\d\+/
  return pattern.test(string)
}

export const calculateExtendedDate = (_data, responseData) => {
  return moment(responseData?.end_date).toISOString()
}

export const checkRegaImageNotFound = (data = {}, isDetailPage = false) => {
  return !isDetailPage
    ? data?.isRegaProp && (!data?.propertyFiles?.images || data?.propertyFiles?.images?.length === 0)
    : data?.isRegaProp && (!data?.images?.data || data?.images?.data?.length === 0)
}
export const REGA_IMG_EN = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-no-img-en.png`
export const REGA_IMG_AR = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-no-img-ar.png`
export const REGA_IMG_MAP_EN = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-card-img-en.png`
export const REGA_IMG_MAP_AR = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-card-img-ar.png`
export const REGA_IMG_EN_NEW = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-no-img-en.png`
export const REGA_IMG_AR_NEW = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/rega-property-default-no-img-ar.png`

// Calculates the total amount for placing a bid in an auction.
export const calculateTotalPlaceBid = (currentHighest, incrementAmount, data, isInfath) => {
  // Check if there are no existing bids
  const hasNoBids = !data?.highestBid
  const totalWithIncrement = Number(currentHighest) + Number(incrementAmount)
  if (!isInfath) return totalWithIncrement
  // If there are no existing bids, return the current highest bid amount
  // Otherwise, return the total amount with increment
  return hasNoBids ? Number(currentHighest) : totalWithIncrement
}

export const generateUUID = () => {
  const array = new Uint32Array(4)
  window?.crypto?.getRandomValues(array)

  return (
    array[0].toString(16).padStart(8, '0') +
    '-' +
    array[1].toString(16).padStart(8, '0') +
    '-' +
    array[2].toString(16).padStart(8, '0') +
    '-' +
    array[3].toString(16).padStart(8, '0')
  )
}

export const setCookieOnLogin = (token, refreshToken, authStoredDate, userID, autoFillDataForMatchmaker = null) => {
  const cookie = new UCookies()
  const domain = location.host

  const cookieOptions = {
    path: '/',
    expires: new Date(new Date().setMonth(new Date().getMonth() + 1)), // 1 month expiry added to cookie
  }
  /**
   * @logic for localhost
   * 1-Check if the domain contains a colon (":")
   * 2-Split the domain into parts using ":"
   * 3-Check if the part before the ":" is "localhost"
   * 4-Remove the "domain" property from "cookieOptions"
   * 5-Set a cookie named "authToken" with the provided "token" and "cookieOptions"
   * 6-Set a cookie named "userId" with the provided "userID" and "cookieOptions"
   */

  if (domain.split('.').length > 1) {
    const domainParts = domain.split('.')
    domainParts.shift()
    //cookieOptions.domain = '.' + domainParts.join('.')
    let isDomainInArray = DomainWithCommonCookies.includes(domain)
    if (isDomainInArray) {
      cookieOptions.domain = '.' + domainParts.join('.')
    } else {
      cookieOptions.domain = '.' + location.host
    }
  } else if (domain.split(':').length > 1) {
    const domainParts = domain.split(':')
    if (domainParts[0] === 'localhost') {
      delete cookieOptions.domain
      handleCookie(cookie, token, userID, authStoredDate, refreshToken, cookieOptions, autoFillDataForMatchmaker)
    }
  }
  if (domain.match(/[a-z]/i) == null) {
    delete cookieOptions.domain
    handleCookie(cookie, token, userID, authStoredDate, refreshToken, cookieOptions, autoFillDataForMatchmaker)
  } else if (domain.split('.').length > 1 && (cookie.get('authToken') == null || cookie.get('authToken') != token)) {
    let isDomainInArray = DomainWithCommonCookies.includes(domain)
    if (isDomainInArray) {
      const domainParts = domain.split('.')
      domainParts.shift()
      cookieOptions.domain = '.' + domainParts.join('.')
    } else {
      cookieOptions.domain = '.' + location.host
    }
    handleCookie(cookie, token, userID, authStoredDate, refreshToken, cookieOptions, autoFillDataForMatchmaker)
  }
}

// Function to detect window size on screen size change
export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const objectRemoveEmptyData = (data) => {
  Object.keys(data).forEach((key) => {
    if (
      data[key] === null ||
      data[key] === '' ||
      (Array.isArray(data[key]) && !data[key].length) ||
      data[key] === undefined
    ) {
      delete data[key]
    }
  })
  return data
}
/**
 * Format and manipulate a numeric value.
 *
 * @param {string} value - The input value to format.
 * @param {boolean} addComma - If true, format the value with commas as thousands separators.
 *                            If false, remove commas and return a numeric value without commas.
 * @returns {number|string} - If addComma is true, returns the formatted string with commas.
 *                           If addComma is false, returns a numeric value without commas.
 */
export const formatInputAmount = (value, addComma = false) =>
  addComma ? parseFloat(value.replace(/[^\d]/g, '')).toLocaleString() : parseFloat(value.replace(/,/g, ''))

// Function to display an error toast message with custom content
export const showErrorToastMessage = (id, lang, message1, message2 = '', _isWallet = false) => {
  showCustomToast(
    <CustomToast showIcon='error' headerText={message1} descriptiveText={message2} />,
    'outbid',
    lang,
    id,
    false,
    { position: 'top-center' },
    true,
  )
}
export const getDataLayer = (data = null, responseData = null, bidDeposit = null) => {
  let dataLayer = {}
  if (data !== null) {
    dataLayer = {
      group_auction_id: data?.infathAuctionGroupInfo?.auctionGroupId,
      auction_id: data?.id,
      seller_type: data?.sellerType,
      status: data?.status,
      available_balance: bidDeposit ?? 0,
      deposit_amount: data?.bidDeposit,
    }
  }
  if (bidDeposit === null) {
    delete dataLayer.available_balance
  }

  if (responseData !== null) {
    const responseDataLayer = {
      invoice_number: responseData?.data?.data?.invoiceNo,
      deposit_amount: responseData?.data?.data?.amount,
      invoice_generation_date: responseData?.data?.data?.createdAt,
    }
    dataLayer = { ...dataLayer, ...responseDataLayer }
  }

  return dataLayer
}

// author : Mahesh Kumar

const validAuctionTypes = ['live', 'upcoming', 'past', 'cancel']

export const getTabsArray = (t, pageType, counts = {}) => {
  return validAuctionTypes.map((type) => {
    let eventType;
    let statusType;

    if (type === 'live') {
      eventType = 'Live';
    } else if (type === 'past') {
      eventType = 'closed';
    } else {
      eventType = type;
    }

    if (type === 'past') {
      statusType = 'closed';
    } else {
      statusType = type;
    }

    const eventName = pageType === 'profile_register' ? `ADP_open_reg_${eventType}` : 'tab_clicked';

    const tab = {
      slug: type,
      label: t(`auctionCommon.${type === 'past' ? 'CLOSED' : type.toUpperCase()}`),
      eventName,
      pageType,
      status: statusType,
    };

    if (pageType !== 'profile_saved') {
      tab.count = counts[statusType];
    }

    return tab;
  });
};

export const getSkeletonClass = (isSocketDisconnected, value) => (isSocketDisconnected ? value : '')

//author:@Fawwad
export const formatIBAN = (iban) => {
  if (!iban) return ''

  // Remove all non-alphanumeric characters, including non-breaking space
  iban = iban.replace(/[^\u0020-\u007E]/g, '')
  // Define the desired format (groups of four characters)
  const groupSize = 4

  // Use regular expressions to split the IBAN into groups of four characters
  return iban.match(new RegExp(`.{1,${groupSize}}`, 'g')).join(' ')
}

/**
 * author:@Fawwad
 * Extracts the file name from a given URL.
 * @param {string} url - The URL from which to extract the file name.
 * @returns {string} - The extracted file name.
 * @returns
 */
export const getFileNameFromURL = (url) => {
  try {
    const urlObject = new URL(url)
    const pathname = urlObject.pathname
    const parts = pathname.split('/')
    return parts[parts.length - 1]
  } catch (e) {
    return url
  }
}

export const numberFormatter = (t, _lang, item) => {
  if (item === undefined || item === null) return
  return t(`shareRequirement.${item?.type}`)
    .replace('max', priceConverter(item?.max))
    .replace('min', priceConverter(item?.min))
}

// below code will extract Video ID from almost any YouTube Link
export const youtubeIdExtract = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const howAuctionWorksVidAndEWalletPDF = async (lang) => {
  const queryParams = {
    slug: 'wallet_working',
    locale: `${lang}${lang === 'ar' ? '-SA' : ''}`,
  }
  const URL = checkStaticURL('auction-info', queryParams)
  const apiResponse = await fetch(URL)
  const apiData = await apiResponse.json()
  return apiData?.data // youtube ID and Recharge your ewallet pdf link will be extracted from this
}
export const propertyTypesorting = (type, attributes, cardCategory) => {
  let priorityOrder = []
  const propertyTypes = ['Villa', 'Apartment', 'Duplex', 'Palace', 'Rest house', 'Chalet', 'Floor']
  if (propertyTypes.includes(type)) {
    priorityOrder = ['noOfBedrooms', 'noOfBathrooms', 'builtUpArea']
  } else if (type === 'Building' || type === 'Farm') {
    priorityOrder = ['builtUpArea', 'completionYear']
  } else if (type === 'Land') {
    priorityOrder = ['carpetArea']
  } else {
    priorityOrder = ['noOfBedrooms']
  }
  const sortedData = attributes.sort((a, b) => {
    const aPriority = priorityOrder.indexOf(a.key)
    const bPriority = priorityOrder.indexOf(b.key)
    if (aPriority === -1 && bPriority === -1) {
      return 0
    } else if (aPriority === -1) {
      return 1
    } else if (bPriority === -1) {
      return -1
    }
    return aPriority - bPriority
  })

  return cardCategory === 'project' ? sortedData.slice(0, 1) : sortedData.slice(0, 3)
}

export const redirectionLocation = (lat, lon) => {
  // Commented so that it can be reviewed in future
  // navigator.geolocation.getCurrentPosition(
  //   (position) => {
  //     window.open(
  //       `https://www.google.com/maps/dir/?api=1&origin=${position.coords.latitude},${position.coords.longitude}&destination=${lat},${lon}`,
  //       '_blank',
  //     )
  //   },
  //   (error) => {
  //     window.open(
  //       `http://maps.google.com?q=${lat},${lon}`,
  //       '_blank',
  //     )
  //   },
  // )
  window.open(`https://www.google.com/maps/dir//${lat},${lon}/@${lat},${lon}?entry=ttu`)
}

// To get deviceType as web/msite
export const getDeviceType = () => {
  if (typeof window !== 'undefined' && window.innerWidth <= 991) {
    return 'msite'
  }
  return 'web'
}

export const orderedArray = (galleryData) => {
  const order = ['videos', 'images', 'brochure', 'additionalDocuments']
  let tabsArray = []
  order?.forEach((item) => {
    if (galleryData.includes(item)) {
      tabsArray.push(item)
    }
  })
  return tabsArray
}

export const isMobileSSR = (context) =>
  Boolean(
    context?.req?.headers['user-agent']?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
  )

const handleCookie = (
  cookie,
  token,
  userID,
  authStoredDate,
  refreshToken,
  cookieOptions,
  autoFillDataForMatchmaker,
) => {
  if (autoFillDataForMatchmaker) {
    cookie.set('routerParams', autoFillDataForMatchmaker, cookieOptions)
  } else {
    cookie.set('authToken', token, cookieOptions)
    cookie.set('userId', userID, cookieOptions)
    cookie.set('refreshToken', refreshToken, cookieOptions)
    cookie.set('authStoredDate', authStoredDate, cookieOptions)
  }
}

  export const getNameToDisplay = (element, userData, t) => {
		if (userData && userData?.data.phoneNumber == element.phoneNumber) {
			return t('auctionHistory.YOU')
		}
    return element?.userId;
	}

  export const getRedirectUrl = (property, locale) => {
    const redirectBaseUrl = process.env.NEXT_PUBLIC_WASALTBASE_URL
    const langPath = locale === 'en' ? '/en' : ''

    if (property.propIdentityTypeId != 96) {
      return `${redirectBaseUrl}${langPath}/property/${property?.propertyInfo?.propertyFor}/${property?.propertyInfo?.slug}`
    } else {
      return `${redirectBaseUrl}${langPath}/project/${property?.projectInfo?.city}/${property.projectInfo.slug}`
    }
  }

  export const getbiddingCTAText = (auctionDetails, t) => {
    const { isRegistered } = auctionDetails
    if(isRegistered){
      return t('auctionBidComponent.PLACE_BID')
    }
    return t('eWallet.registerNow')
  }
