var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tjGtBo5QUYk4gzRmnqXOj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true' &&
  Sentry.init({
    dsn: SENTRY_DSN || "https://4fc025f8844620888854a3ef7963525d@o1432095.ingest.sentry.io/4506420813824000",
    // controls transactions sample rate (use 1.0 when in development to capture 100% transactions)
    tracesSampleRate: 0.3,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    /* defaultIntegrations: false, */
    integrations: (integrations) => [new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true,
    }), ...integrations.filter(integration => integration.name !== 'Console')],
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
    ],
    replaysSessionSampleRate: 0, //sample 10% of all sessions
    replaysOnErrorSampleRate: 1.0, //sample all sessions with errors
  })

